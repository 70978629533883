import {
  deleteReaction,
  postReaction
} from '@/services/community/reactionsService';
import { useCallback, useEffect, useState } from 'react';

const THROTTLE_TIME = 500;

const usePostReaction = ({
  post,
  postType,
  communityId,
  postId,
  commentId
}) => {
  const [isPostLiked, setIsPostLiked] = useState(false);
  const [isPostReactionDisabled, setIsPostReactionDisabled] =
    useState(false);

  const isPostInitiallyLiked =
    post?.currentUserReactionData?.type === 'like';

  const postLikeCount = (function () {
    let postLikes = post?.reactionCounts?.like ?? 0;

    if (isPostInitiallyLiked && !isPostLiked) return postLikes - 1;

    if (!isPostInitiallyLiked && isPostLiked) return postLikes + 1;

    return postLikes;
  })();

  const handleReactionButtonClick = useCallback(
    async function (e) {
      e?.stopPropagation();
      if (isPostReactionDisabled) {
        return;
      }
      setIsPostReactionDisabled(true);
      const initialState = isPostLiked;

      try {
        if (!isPostLiked) {
          setIsPostLiked(true);
          const res = await postReaction({
            postType,
            communityId,
            postId,
            commentId,
            reactionType: 'like'
          });
          if (res.error) {
            setIsPostLiked(false);
          }
        } else {
          setIsPostLiked(false);
          const res = await deleteReaction({
            postType,
            communityId,
            postId,
            commentId,
            reactionType: 'like'
          });
          if (res.error) {
            setIsPostLiked(true);
          }
        }
      } catch (e) {
        setIsPostLiked(initialState);
      }
      setTimeout(() => {
        setIsPostReactionDisabled(false);
      }, THROTTLE_TIME);
    },
    [
      commentId,
      communityId,
      isPostLiked,
      isPostReactionDisabled,
      postId,
      postType
    ]
  );

  useEffect(() => {
    if (post?.currentUserReactionData) {
      setIsPostLiked(post?.currentUserReactionData?.type === 'like');
    }
  }, [post?.currentUserReactionData]);

  return {
    isPostLiked,
    postLikeCount,
    handleReactionButtonClick
  };
};

export default usePostReaction;
