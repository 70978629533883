import {
  DecoratorBlockNode,
  SerializedDecoratorBlockNode
} from '@lexical/react/LexicalDecoratorBlockNode';
import {
  type DOMConversionMap,
  type DOMConversionOutput,
  type DOMExportOutput,
  type LexicalEditor,
  type LexicalNode,
  type NodeKey,
  type SerializedLexicalNode,
  type Spread
} from 'lexical';
import { Suspense } from 'react';

import { ImageIdentity } from '../plugins/ImagesPlugin';
import ImageComponent from './ImageComponent';

let currentImageIdentity: ImageIdentity | null = null;

export interface ImagePayload {
  src: string;
  altText?: string;
  height?: number;
  imageIdentity?: ImageIdentity;
  key?: NodeKey;
  maxWidth?: number | string;
  width?: number | string;
}

export function $createImageNode({
  altText,
  maxWidth = '100%',
  width,
  src,
  imageIdentity
}: ImagePayload): ImageNode {
  return new ImageNode(
    src,
    maxWidth,
    altText,
    imageIdentity,
    width,
    'auto'
  );
}

const convertImageElement = (
  domNode: Node
): null | DOMConversionOutput => {
  if (domNode instanceof HTMLImageElement) {
    const { alt: altText, src, width, height } = domNode;
    const node = $createImageNode({
      altText,
      height,
      src,
      width,
      imageIdentity: currentImageIdentity
    });
    return { node };
  }
  return null;
};

export type SerializedImageNode = Spread<
  {
    altText: string;
    maxWidth: number | string;
    src: string;
    height?: number;
    imageIdentity?: ImageIdentity;
    width?: number | string;
  },
  SerializedLexicalNode
>;

export class ImageNode extends DecoratorBlockNode {
  __src: string;
  __altText?: string;
  __width: 'inherit' | number | string;
  __height: 'inherit' | number | string;
  __maxWidth: number | string;
  __imageIdentity?: ImageIdentity;

  static getType(): string {
    return 'image';
  }

  static clone(node: ImageNode): ImageNode {
    return new ImageNode(
      node.__src,
      node.__maxWidth,
      node.__altText,
      node.__imageIdentity,
      node.__width,
      node.__height,
      node.__key
    );
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('img');
    element.setAttribute('src', this.__src);
    if (this.__altText) {
      element.setAttribute('alt', String(this.__altText));
    }
    element.setAttribute('width', this.__width.toString());
    element.setAttribute('height', this.__height.toString());
    element.classList.add('rounded-12', 'my-12');
    return { element };
  }

  static importDOM(): DOMConversionMap | null {
    return {
      img: () => ({
        conversion: convertImageElement,
        priority: 0
      })
    };
  }

  static importJSON(serializedNode: SerializedImageNode) {
    const { altText, height, width, maxWidth, src, imageIdentity } =
      serializedNode;
    const node = $createImageNode({
      altText,
      height,
      maxWidth,
      src,
      width,
      imageIdentity
    });
    return node;
  }

  constructor(
    src: string,
    maxWidth: number | string,
    altText?: string,
    imageIdentity?: ImageIdentity,
    width?: 'inherit' | number | string,
    height?: 'inherit' | number | string,
    key?: NodeKey
  ) {
    super('left', key);
    this.__src = src;
    this.__altText = altText;
    this.__maxWidth = maxWidth;
    this.__width = width || 'inherit';
    this.__height = height || 'inherit';
    this.__imageIdentity = imageIdentity;
  }

  exportJSON(): SerializedDecoratorBlockNode & {
    height: string | number | 'inherit';
    maxWidth: number | string;
    src: string;
    width: string | number | 'inherit';
    altText?: string;
    imageIdentity?: ImageIdentity;
  } {
    return {
      format: this.__format,
      altText: this.getAltText(),
      height: this.__height === 'inherit' ? 0 : this.__height,
      maxWidth: this.__maxWidth,
      src: this.getSrc(),
      type: 'image',
      version: 1,
      width: this.__width === 'inherit' ? 0 : this.__width,
      imageIdentity: this.__imageIdentity
    };
  }

  setWidthAndHeight(
    width: 'inherit' | number,
    height: 'inherit' | number
  ): void {
    const writable = this.getWritable();
    writable.__width = width;
    writable.__height = height;
  }

  // View

  createDOM(): HTMLElement {
    const p = document.createElement('p');
    p.className = 'editor-image';
    return p;
  }

  updateDOM(): false {
    return false;
  }

  getSrc(): string {
    return this.__src;
  }

  getAltText(): string | undefined {
    return this.__altText;
  }

  setSrc(src: string): void {
    const writable = this.getWritable();
    writable.__src = src;
  }

  setWidth(src: string): void {
    const writable = this.getWritable();
    writable.__width = src;
  }

  decorate(_editor: LexicalEditor): JSX.Element {
    return (
      <Suspense fallback={null}>
        <ImageComponent
          src={this.__src}
          altText={this.__altText}
          width={this.__width}
          height={this.__height}
          nodeKey={this.getKey()}
          resizable={false}
          imageIdentity={this.__imageIdentity}
        />
      </Suspense>
    );
  }

  isTopLevel() {
    return true;
  }
}

export function $initializeImageIdentity(imageIdentity: ImageIdentity) {
  currentImageIdentity = imageIdentity;
}

export function $isImageNode(
  node: LexicalNode | null | undefined
): node is ImageNode {
  return node instanceof ImageNode;
}
